import React, { useMemo, useState } from "react";
import { graphql } from "gatsby";
import createRelatedResources from "src/components/helpers/createRelatedResources";
import usePrependBaseURLIntoImages from "src/components/helpers/usePrependBaseURLIntoImages";
import { get } from "src/utils";
import extractMarketoValues from "src/components/helpers/extractMarketoIdAndSerial";
import getContentConnectorFields, { getContentConnectorAnchors } from "src/components/helpers/getContentConnectorFields";
import findSiteType from "src/components/helpers/findSiteType";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import getTeaserCarouselDataFromResource from "src/components/helpers/getTeaserCarouselDataFromResource";
import getSchemaMarkup from "src/components/helpers/getSchemaMarkup"
import loadable from '@loadable/component'
import getBannerData from "src/components/helpers/getBannerData";
import { getCommonHeroImages } from "src/utils";
import "src/scss/solutions/_solutionPages.scss";
import "src/scss/solutions/_newSolutionPages.scss";
import { SEO, Banner, CommonHero } from "src/components/commonComponents";
import { getPageDataJsonPath } from "src/utils"
import { useLazyLoadOnScroll } from "src/hooks/useLazyLoadOnScroll";
import { HeroLogos } from "src/components/commonComponents";
const DemoFormWithBg = loadable(() => import('src/components/commonComponents/forms/DemoFormWithBg'))
const RelatedProductCard = loadable(() => import('src/components/commonComponents'), { resolveComponent: (components) => components.RelatedProductCard });
const CaseStudiesBlock = loadable(() => import("src/components/blocks/CaseStudiesBlock"))
const Resources = loadable(() => import('src/components/solutions/Resources'))
const ContentConnector = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.ContentConnector
});
const TeaserCarouselBlock = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.TeaserCarouselBlock
});
const ScrollLinks = loadable(() => import('src/components/commonComponents'), { resolveComponent: (components) => components.SecondaryNavbar });
const CompanyLogos = loadable(() => import("src/components/solutions/CompanyLogos"))
const FooterCta = loadable(() => import('src/components/services/footerCta'))
const DemoForm = loadable(() => import('src/components/solutions/DemoForm'))
// const QuickLinks = loadable(() => import('src/components/solutions/QuickLinks'))
const QuickLinks = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.QuickLinks
});
// const MyAccruentBlock = loadable(() => import('src/components/solutions/MyAccruentBlock'))
const MyAccruentBlock = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.MyAccruentBlock
});




const prefixLangcode = require('src/../helpers/prefixLangcode')
const getImageObject = require("src/../helpers/getImageObject");





const SolutionsTemplate = ({ location, pageContext, path, data }) => {
  const [poupLoad, setPopupLoad] = useState(false)
  const { locale } = pageContext;
  const relatedResourcesData = createRelatedResources(data?.allSitePage?.nodes, {
    hasDescription: true,
  });
  const shouldLoad = useLazyLoadOnScroll();
  const genericCtaEntity = pageContext?.relationships?.fieldGenericCta
  const relatedProductsEntity = pageContext?.relationships?.fieldRelatedProducts
  const relatedResourcesEntity = pageContext?.relationships?.fieldRelatedResourceSection
  const newResourceReferenceField = relatedResourcesEntity?.relationships?.fieldResourceReference?.length > 0 && relatedResourcesEntity?.relationships?.fieldRelatedItem?.length === 0 ? true : false
  const resourceCarouselSection = pageContext?.relationships?.fieldResourceCarouselSection
  const relatedResources = createRelatedResources(newResourceReferenceField ? relatedResourcesEntity?.relationships?.fieldResourceReference : relatedResourcesEntity?.relationships?.fieldRelatedItem, {
    isTaxonomyPage: true,
    hasDescription: true,
    locale,
    newResourceReferenceField: newResourceReferenceField
  });
  const bannerData = getBannerData(pageContext?.relationships?.fieldReportBannerSection);
  const fieldHideSidebar = pageContext?.fieldHideSidebar
  usePrependBaseURLIntoImages("solutions-wrapper");
  let contentConnectors = [];
  if (Array.isArray(pageContext?.relationships?.fieldContentConnector)) {
    contentConnectors = getContentConnectorFields(
      pageContext?.relationships?.fieldContentConnector,
      "solutionPage",
      locale
    );
  }
  const anchors = getContentConnectorAnchors(contentConnectors)?.map(c => ({ ...c, id: c.id }));
  const heroImage = pageContext?.commonHeroProps?.heroImage
  const schemaMarkup = getSchemaMarkup(pageContext?.fieldSchemaMarkup)
  const schemaMarkupVideo = getSchemaMarkup(pageContext?.fieldVideoSchemaMarkup)
  // const heroImage = get(
  //   pageContext,
  //   "fieldHeroImage.hero"
  // );
  /**
 * @Open_Graph_Markup
 * Some of the data inside the objects will be done inside SEO component
 */
  const OGMarkup = getOGMarkup(pageContext?.fieldPageTitle, 'solution', { gatsbyImage: heroImage?.shareImage });
  const { entityMetatags: metaTags } = pageContext;
  // url - set inside SEO
  // description - set inside SEO
  // Locale - left as default: en_US
  // type - left as default: Webpage
  const fieldMarketoFormTitleId = get(pageContext, "relationships.fieldRefMarketoForm.title");
  const formData = pageContext.relationships?.fieldRefMarketoForm;
  const { id, serial, gaLabel } = extractMarketoValues(
    formData?.fieldEmbedCode
  );
  pageContext?.fieldResourceCarouselSection?.entity ? "solution-case-study-carousel" :
    (pageContext.fieldAddRelated &&
      relatedResourcesData &&
      relatedResourcesData.length !== 0) ? "solution-related-resources" :
      (relatedResources?.length !== 0) ? "solution-related-resources-new" :
        genericCtaEntity?.status ? "solution-generic-cta" : ""
  const checkForVersion2 = pageContext?.fieldHeroStyle === "hero_version_2"
  const contentCarouselSection = pageContext?.relationships?.fieldResourceCarouselSection ? getTeaserCarouselDataFromResource(pageContext?.relationships?.fieldResourceCarouselSection) : null
  const checkForRelatedProductReferences = (relatedProductsEntity?.relationships?.fieldProductReference && relatedProductsEntity?.relationships?.fieldProductReference.length !== 0 && relatedProductsEntity?.status)
  const isAccruent = findSiteType('accruent');
  const isMc = findSiteType('mc');
  const isEms = findSiteType('ems');
  const carouselBrandLogo = pageContext?.relationships?.fieldResourceCarouselSection?.relationships?.fieldBrandLogosSection;
  const siteClass = isMc ? "new-solution-page-mc tw-new-solution-page-mc" : isEms ? "new-solution-page-ems tw-new-solution-page-ems" : "new-solution-page tw-new-solution-page"
  let isSideBar = ((relatedProductsEntity?.relationships?.fieldRelatedItems
    && relatedProductsEntity?.relationships?.fieldRelatedItems.length !== 0
    && relatedProductsEntity?.status)
    || (isAccruent && !pageContext.fieldHideHero)) && !!!fieldHideSidebar
  let hreflangAlternatives = []
  pageContext.hreflangData?.forEach(hrefData => {
    hreflangAlternatives.push({
      href: hrefData.path,
      hreflang: hrefData.locale
    })
  });
  hreflangAlternatives?.find(trans => {
    if (trans.hreflang == "en") {
      hreflangAlternatives.push({
        href: trans.href,
        hreflang: "x-default"
      })
    } else if (hreflangAlternatives.length < 2) {
      if (trans.hreflang !== "en") {
        hreflangAlternatives.push({
          href: trans.href,
          hreflang: "x-default"
        })
      }
    }
  })

  let relatedProductLogos = []
  if (isAccruent) {
    const relatedItemes = checkForRelatedProductReferences ? relatedProductsEntity?.relationships?.fieldProductReference?.map(item => item?.relationships?.fieldProductReferenceItem) : relatedProductsEntity?.relationships?.fieldRelatedItems
    relatedProductLogos = relatedItemes?.map(item => ({ logo: getImageObject(item?.relationships?.fieldProductLogo, item?.fieldProductLogo), link: prefixLangcode(item?.entityUrl?.path, locale) }))
  }
  const preloadImages = useMemo(() => getCommonHeroImages(pageContext?.commonHeroProps), [pageContext?.commonHeroProps])
  const pageDataPath = getPageDataJsonPath(pageContext?.entityUrl?.path)
  const search = location.search;
  hreflangAlternatives = search.length > 0 ? [] : hreflangAlternatives
  const isHeroWithSideContent = pageContext?.commonHeroProps?.fieldHeroHalfMask ? pageContext?.commonHeroProps?.fieldHeroHalfMask : false
  const quickLinks = pageContext?.relationships?.field_cta_block?.relationships?.fieldCtaS
  const quickLinksTitle = pageContext?.relationships?.fieldQuickLinks?.title;
  return (
    <div pagetype="taxonomy" tid={pageContext?.entityId} className={siteClass}>
      <SEO
        lang={locale}
        pageUrl={pageContext?.entityUrl?.path}
        title={pageContext?.fieldPageTitle}
        meta={metaTags}
        schemaMarkup={schemaMarkup}
        schemaMarkupVideo={schemaMarkupVideo}
        OGMarkup={OGMarkup}
        alternatives={hreflangAlternatives}
        preloads={[pageDataPath]}
        preloadedImages={preloadImages}
        isPreloadImageOnMobile={false}
      />
      {!pageContext.fieldHideHero && (
        <CommonHero
          commonHeroProps={pageContext?.commonHeroProps}
          contentProp={!checkForVersion2 ? "Solution" : "MC Solution"}
          onPopupLoad={setPopupLoad}
        />
      )}
      {/* {isHeroWithSideContent && isAccruent && !!relatedProductLogos?.length &&
        <HeroLogos relatedProductLogos={relatedProductLogos} isHeroWithSideContent={isHeroWithSideContent} />
      } */}
      {/* {(bannerData?.fieldShowReportBanner && bannerData?.status) && (
        <div className="container">
          <Banner
            {...bannerData}
          />
        </div>
      )} */}
      {/* {!isHeroWithSideContent && isAccruent && !!relatedProductLogos?.length &&
        <HeroLogos relatedProductLogos={relatedProductLogos} isHeroWithSideContent={isHeroWithSideContent} />
      } */}
      {/* {!!anchors.length &&
        <ScrollLinks
          title={pageContext.fieldTermJumpLinksHeader}
          anchors={anchors}
        />
      } */}
      <div className="container content-visibility-auto-mobile ">
        <div className={`${(isSideBar) ? "outer-container-division columns pt-0" : ""}`}>
          <div className={`${(isSideBar) ? "column is-8-desktop is-12-mobile inner-content-container-division" : ""}`}>
            {contentConnectors.map((data, index) => {
              return (
                <div key={index} className={`section-container solution-template ${(!anchors.length && index == 0) ? (!pageContext.fieldHideHero) ? 'rm-margin' : 'set-margin' : ''}`} style={{ position: 'relative' }}>
                  <ContentConnector
                    data={data}
                    key={index}
                    pageType={pageContext.type?.id}
                    checkForRelatedProductCard={isSideBar}
                    path={path}
                    locale={locale}
                    isSolutionsSidebar={isSideBar}
                    pageTitleForMarketo={pageContext?.commonHeroProps?.heading}
                  />
                </div>
              )
            })}
          </div>
          {isSideBar &&
            <div className="column pt-0 is-4-desktop is-12-mobile  inner-solution-products-division sidebar-content">
              <div className="right-box">
                <RelatedProductCard entity={relatedProductsEntity} locale={locale} isRelatedProductReferences={checkForRelatedProductReferences} />
                {/* {isAccruent && (<>
                  <MyAccruentBlock />
                  <QuickLinks data={quicklinks} title={quickLinksTitle} locale={locale}/>
                </>)} */}
              </div>
            </div>
          }
        </div>
        {pageContext?.relationships?.fieldCaseStudies &&
          <div className="section-container solution-template" >
            <section className="section solution-case-study">
              <CaseStudiesBlock
                contentCaseStudiesSection={pageContext?.relationships?.fieldCaseStudies}
                locale={locale}
              />
            </section>
          </div>
        }
        {resourceCarouselSection &&
          <div className="section-container solution-template" >
            <section className="section solution-case-study-carousel">
              {carouselBrandLogo && (
                <CompanyLogos
                  data={getImageObject(carouselBrandLogo?.relationships?.fieldLogoImages, carouselBrandLogo?.fieldLogoImages, "fieldLogoImages")}
                  fieldTitle={
                    !carouselBrandLogo.fieldHideTitle ? carouselBrandLogo.title : null
                  }
                  nid={carouselBrandLogo.nid}
                  entityId={pageContext?.entityId}
                  fieldBody={carouselBrandLogo?.fieldBody}
                  fieldLogoStyle={carouselBrandLogo?.fieldLogoStyle}
                  fieldShowCtaButton={carouselBrandLogo?.fieldShowCtaButton}
                  fieldCtaUrl={carouselBrandLogo?.fieldCtaUrl}
                  isTeaser={true}
                />
              )}
              <TeaserCarouselBlock
                contentCarouselSection={contentCarouselSection}
                fieldResourceCarouselItem={resourceCarouselSection?.relationships?.fieldResourceCarouselItem}
                locale={locale}
              />
            </section>
          </div>
        }
        {/*  MARKETO FORM  */}
        {/* {formData?.fieldVersions !== "advanced" ? <section className={"section taxonomy-form-wrapper full-width-bg-wrapper"}>
          <DemoForm
                fieldFormHeader={formData?.fieldFormHeader}
                description={formData?.fieldFormText?.value}
                image={getImageObject(formData?.relationships?.fieldFormImage, formData?.fieldFormImage)}
                fieldMarketoFormTitleId={fieldMarketoFormTitleId}
                serial={serial}
                formId={id}
                gaLabel={gaLabel}
                shouldLoad={(shouldLoad || poupLoad)}
              />
        </section>
        :
        <section className="section taxonomy-form-wrapper tw-demo-form-bg">
          {<DemoFormWithBg
            fieldFormHeader={formData?.fieldFormHeader}
            description={formData?.fieldFormText?.value}
            image={getImageObject(formData?.relationships?.fieldFormImageWithCover, formData?.fieldFormImage)}
            bgImage={getImageObject(formData?.relationships?.fieldMediaImage?.relationships?.field_image, formData?.relationships?.fieldMediaImage?.fieldImage)}
            fieldMarketoFormTitleId={fieldMarketoFormTitleId}
            serial={serial}
            formId={id}
            gaLabel={gaLabel}
            pageTitle={pageContext?.commonHeroProps?.heading}
            cta={formData?.fieldCtaUrl}
            shouldLoad={(shouldLoad)}
          />}
        </section>
      } */}
        

        {pageContext.fieldAddRelated &&
          relatedResourcesData &&
          relatedResourcesData.length !== 0 && (
            <section className="section-container solution-template section solution-related-resources">
              <h3 className="title title-3">
                Resources
              </h3>
              <p className="solution-resource-sub-heading">Power dynamic work and school environments with a variety of use cases, multiple points of access and seamless integrations.</p>
              <Resources title="" isCarousel={false} data={relatedResourcesData} />
            </section>
          )}
        {
          relatedResources &&
          relatedResources.length !== 0 && (
            <section className="section-container solution-template section solution-related-resources-new">
              <Resources title={relatedResourcesEntity?.fieldHideTitle ? undefined : relatedResourcesEntity?.title} isCarousel={false} data={relatedResources} />
            </section>
          )}
          {!!quickLinks?.length && 
            <div className="section-container">
              <QuickLinks fieldCtaBlock={pageContext?.relationships?.field_cta_block} quickLinks={quickLinks} title={quickLinksTitle} locale={locale}/>
            </div>
          }
        {!!genericCtaEntity?.status &&
          <div className="container">
            <div className="section-container solution-template" component="FooterCta">
              <section className="section solution-generic-cta">
                <div className="footerCta-section has-word-break has-text-centered">
                  <FooterCta entity={genericCtaEntity} />
                </div>
              </section>
            </div>
          </div>}
      </div>

    </div>
  );
};

export default SolutionsTemplate;

export const query = graphql`
  query resourcesBySolutionId($pageId: String, $locale: String) {
    allSitePage(
      limit: 3
      filter: {componentChunkName: {eq: "component---src-templates-common-resources-template-js"}, context: {locale: {eq: $locale}, solutionIds: {elemMatch: {solutionId: {eq: $pageId}}}}}
    ) {
      nodes {
        ...resourceCards
      }
    }
  }
`;



